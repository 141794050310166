.tgs-data-grid.MuiDataGrid-root {
  width: 100%;
  border-radius: 0 !important;
  z-index: 100;
  background: rgba(30, 30, 30, 0.9);

  .MuiDataGrid-main div:has(> .MuiLinearProgress-root) {
    top: 38px !important;
  }

  .MuiDataGrid-sortIcon {
    display: none;
  }

  .MuiDataGrid-columnHeaders {
    outline: 1px solid #4b4b4b;
    border-top-left-radius: 0;
    border-top-right-radius: 0;

    .MuiDataGrid-columnHeadersInner > div:first-of-type {
      height: 38px;
    }

    .MuiDataGrid-columnHeader {
      height: 38px !important;

      .MuiDataGrid-columnSeparator {
        min-height: 38px !important;
      }
    }

    .MuiDataGrid-columnHeaderTitle {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.17px;
      color: rgba(255, 255, 255, 1);
    }

    // grid header select all
    .MuiDataGrid-columnHeaderTitleContainerContent {
      .MuiCheckbox-root {
        svg.MuiSvgIcon-root {
          transform: translate(4px, 4px);
        }

        // unchecked
        input[data-indeterminate="false"] + svg.MuiSvgIcon-root {
          path {
            d: path(
              "M1.5 14.7383C1.0875 14.7383 0.734375 14.5914 0.440625 14.2977C0.146875 14.0039 0 13.6508 0 13.2383V2.23828C0 1.82578 0.146875 1.47266 0.440625 1.17891C0.734375 0.885157 1.0875 0.738281 1.5 0.738281H12.5C12.9125 0.738281 13.2656 0.885157 13.5594 1.17891C13.8531 1.47266 14 1.82578 14 2.23828V13.2383C14 13.6508 13.8531 14.0039 13.5594 14.2977C13.2656 14.5914 12.9125 14.7383 12.5 14.7383H1.5ZM1.5 13.2383H12.5V2.23828H1.5V13.2383Z"
            );
          }
        }

        // checked
        &.Mui-checked {
          svg.MuiSvgIcon-root {
            path {
              d: path(
                "M5.9375 10.7383L10.8958 5.80078L9.83333 4.73828L5.9375 8.61328L4.16667 6.86328L3.10417 7.92578L5.9375 10.7383ZM1.5 14.7383C1.0875 14.7383 0.734375 14.5914 0.440625 14.2977C0.146875 14.0039 0 13.6508 0 13.2383V2.23828C0 1.82578 0.146875 1.47266 0.440625 1.17891C0.734375 0.885157 1.0875 0.738281 1.5 0.738281H12.5C12.9125 0.738281 13.2656 0.885157 13.5594 1.17891C13.8531 1.47266 14 1.82578 14 2.23828V13.2383C14 13.6508 13.8531 14.0039 13.5594 14.2977C13.2656 14.5914 12.9125 14.7383 12.5 14.7383H1.5ZM1.5 13.2383H12.5V2.23828H1.5V13.2383Z"
              );
              fill: #42a5f5;
            }
          }
        }

        // indeterminate
        &.MuiCheckbox-indeterminate {
          input[data-indeterminate="true"] + svg.MuiSvgIcon-root {
            path {
              d: path(
                "M4 8.48828H10V6.98828H4V8.48828ZM1.5 14.7383C1.0875 14.7383 0.734375 14.5914 0.440625 14.2977C0.146875 14.0039 0 13.6508 0 13.2383V2.23828C0 1.82578 0.146875 1.47266 0.440625 1.17891C0.734375 0.885157 1.0875 0.738281 1.5 0.738281H12.5C12.9125 0.738281 13.2656 0.885157 13.5594 1.17891C13.8531 1.47266 14 1.82578 14 2.23828V13.2383C14 13.6508 13.8531 14.0039 13.5594 14.2977C13.2656 14.5914 12.9125 14.7383 12.5 14.7383H1.5ZM1.5 13.2383H12.5V2.23828H1.5V13.2383Z"
              );
              fill: #42a5f5;
            }
          }
        }
      }
    }
  }

  // grid row
  .MuiDataGrid-row {
    svg.MuiSvgIcon-root {
      transform: translate(4px, 4px);
      path {
        d: path(
          "M1.5 14.7383C1.0875 14.7383 0.734375 14.5914 0.440625 14.2977C0.146875 14.0039 0 13.6508 0 13.2383V2.23828C0 1.82578 0.146875 1.47266 0.440625 1.17891C0.734375 0.885157 1.0875 0.738281 1.5 0.738281H12.5C12.9125 0.738281 13.2656 0.885157 13.5594 1.17891C13.8531 1.47266 14 1.82578 14 2.23828V13.2383C14 13.6508 13.8531 14.0039 13.5594 14.2977C13.2656 14.5914 12.9125 14.7383 12.5 14.7383H1.5ZM1.5 13.2383H12.5V2.23828H1.5V13.2383Z"
        );
      }
    }
    &.Mui-selected {
      svg.MuiSvgIcon-root {
        path {
          d: path(
            "M5.9375 10.7383L10.8958 5.80078L9.83333 4.73828L5.9375 8.61328L4.16667 6.86328L3.10417 7.92578L5.9375 10.7383ZM1.5 14.7383C1.0875 14.7383 0.734375 14.5914 0.440625 14.2977C0.146875 14.0039 0 13.6508 0 13.2383V2.23828C0 1.82578 0.146875 1.47266 0.440625 1.17891C0.734375 0.885157 1.0875 0.738281 1.5 0.738281H12.5C12.9125 0.738281 13.2656 0.885157 13.5594 1.17891C13.8531 1.47266 14 1.82578 14 2.23828V13.2383C14 13.6508 13.8531 14.0039 13.5594 14.2977C13.2656 14.5914 12.9125 14.7383 12.5 14.7383H1.5ZM1.5 13.2383H12.5V2.23828H1.5V13.2383Z"
          );
          fill: #42a5f5;
        }
      }
    }

    &.Mui-selected {
      background-color: rgba(56, 78, 89, 0.9);
    }
  }

  .MuiTablePagination-toolbar {
    min-height: 40px;
  }

  .MuiDataGrid-footerContainer {
    min-height: 50px;
    outline: 1px solid #4b4b4b;
  }

  .MuiDataGrid-cell {
    font-weight: 400;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.17px;
    border-bottom: 1px solid #4b4b4b;
    color: rgba(255, 255, 255, 0.7);
  }

  &.data-loading {
    .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer,
    .MuiDataGrid-cellCheckbox {
      pointer-events: none;
      cursor: default;

      .MuiSvgIcon-root {
        color: rgba(255, 255, 255, 0.3);
      }
    }
  }
}

.grid-plot-content {
  height: calc(100% - 40px);

  &.full-screen-mode-grid {
    height: 96%;
  }

  &.hide-grid {
    display: none;
  }

  .grid-panel {
    width: 100% !important;
    height: 100%;
    position: relative;
  }

  .grid-full-width {
    width: 100vw;
    transition: width 225ms;

    &.app-level-open {
      width: calc(100vw - 64px);
    }

    &.panel-open {
      width: calc(100vw - 450px);
    }
  }
}

.grid-custom-footer.MuiTypography-root {
  padding-right: 13px;
  padding-bottom: 5px;
  padding-top: 5px;
  text-align: right;
  font-size: 0.813rem;
}

.grid-menu-header {
  background: rgb(255 255 255 / 30%);
  padding: 2px 15px;
  height: 20px;
  p {
    font-size: 0.625rem;
  }
}

.expression-builder-paper-container {
  .grid-menu-header {
    + .MuiBox-root {
      padding: 15px;
    }
  }
}

.grid-toggle-columns-form {
  padding: 10px 0 0 10px !important;
  display: block !important;
  width: 300px;

  .column-list-container {
    height: 300px;
    display: block;
    overflow-y: scroll;

    .column-list {
      display: flex;
      margin-left: 0;
      .MuiTypography-root {
        font-size: 14px !important;
      }
    }
  }

  .search-field {
    padding-right: 10px;
    margin-bottom: 20px;
    .MuiInputBase-input {
      font-size: 14px !important;
    }
  }

  .helper-text {
    text-align: center;
  }
}

.grid-toolbar-control {
  margin-left: auto;

  button {
    padding: 5px;
  }

  svg {
    color: rgba(255, 255, 255, 0.8);
    width: 18px;
    height: 18px;
  }
}

.grid-toolbar-menu {
  display: flex;

  .grid-toolbar-menu-item {
    .MuiTypography-root {
      flex: 1;
      font-size: 14px;
    }

    &:hover {
      background-color: #1976d2;
    }
  }
}

.circular-progress {
  max-height: 18px;
  max-width: 18px;
  margin-left: auto;
}

.grid-container {
  display: flex;
  width: 100%;
  height: calc(100% - 42px);
  flex-direction: column;
}

.grid-modular-window {
  position: absolute;
  z-index: 1300;
  bottom: 23px;
  right: 0px;
  width: 100%;

  &.hide-view {
    z-index: 700;
  }

  &.grid-header-height {
    height: 82px;
  }

  &.app-level-open {
    width: calc(100vw - 50px);

    &.left-panel-open {
      width: calc(100vw - 450px);

      &.dst-panel-open {
        width: calc(100vw - 750px);
      }

      &.analysis-panel-open {
        width: calc(100vw - 915px);
        right: 465px;

        &.dst-panel-open {
          width: calc(100vw - 1215px);
        }
      }
    }

    &.analysis-panel-open {
      width: calc(100vw - 515px);
      right: 465px;
    }
  }

  &.analysis-panel-open {
    width: calc(100vw - 450px);
    right: 450px;
  }

  &.expanded-view {
    bottom: 0;
    width: calc(100vw - 50px);
  }

  &.full-screen {
    height: 100%;
    z-index: 1300;
  }

  .MuiCollapse-root {
    border-right: 1px solid #bdc3c7;
  }
}

// SearchResultGrid
.grid-header-section {
  min-height: 40px;
  background: rgba(40, 40, 40, 0.9);
  display: flex;
  height: 40px;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  transition: width 225ms;
  padding-left: 20px;
  overflow-x: auto;
  overflow-y: hidden;

  &.full-screen-mode {
    height: 4% !important;
  }
}
.left-panel-open.analysis-panel-open,
.left-panel-open,
.analysis-panel-open {
  div:not(.super-grid).grid-container.small-sized {
    // Check if this is still needed
    // This causes the text to be smaller for permits
    // .grid-count-container {
    //   gap: 9px;
    //   flex: 0 0 500px;

    //   .count-group {
    //     align-items: center;

    //     .MuiTypography-root {
    //       font-size: 8px;
    //     }

    //     .count-value {
    //       &.MuiTypography-root {
    //         font-size: 14px;
    //       }
    //     }
    //   }
    // }

    .grid-toolbar-control {
      gap: 9px;

      &:has(> :last-child:nth-child(4)) {
        flex: 0 0 120px;
      }

      &:has(> :last-child:nth-child(3)) {
        flex: 0 0 90px;
      }
    }
  }
}

.grid-count-container {
  display: flex;
  align-items: center;
  height: 30px;
  gap: 16px;

  .count-group {
    display: flex;
    align-items: flex-end;
    height: inherit;
    padding-bottom: 4px;
    gap: 5px;

    .MuiTypography-root {
      font-size: 13px;
      font-weight: 400;
      line-height: 22px;
      letter-spacing: 0.8px;
      color: #ffffffb2;
    }

    .count-value {
      &.MuiTypography-root {
        font-size: 13px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0.8px;
        color: #ffffff;
      }
      &.selected-analysis {
        color: #42a5f5;
      }
    }
  }

  .MuiDivider-root {
    height: 30px;
    width: 1px;
    background-color: rgba(255, 255, 255, 0.3) !important;
  }
}

.super-grid {
  position: fixed;
  z-index: 500;
  left: 0px;
  top: 49px;
  height: calc(100vh - 55px);
  width: 100vw;
  padding: 2.5px;
}

.default-super-grid-background {
  height: 100%;
}

.super-grid-background {
  z-index: 500;
  top: 55px;
  left: 0px;
  position: fixed;
  height: calc(100vh - 55px);
  width: 100vw;
  background-color: rgb(18, 18, 18);
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.16),
    rgba(255, 255, 255, 0.16)
  );
}

.grid-analysis-button {
  margin-left: 115px;
  position: relative;
  margin-top: -30px;
  margin-right: 10px;
}

.MuiDataGrid-menu .MuiPaper-root {
  overflow: hidden;
}

button.grid-well-panel-button {
  width: 24px;
  height: 24px;

  &.toggled-on {
    background-color: #1976d2;
  }
}
